// import i18n from "../i18n";
import store from "../store";

const Trans = {
  i18nRoute(to) {
    const { param } = store.getters.locale;
    return {
      ...to,
      params: { locale: param, ...to.params }
    };
  }
};

export { Trans };
