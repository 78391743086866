import Vue from "vue";
import Vuetify from "vuetify/lib";

import ja from "vuetify/es5/locale/ja";
import en from "vuetify/es5/locale/en";

import "@mdi/font/css/materialdesignicons.css";

// Custom Icons
import IconRocket from "components/icons/IconRocket.vue";
import IconGlobal from "components/icons/IconGlobal.vue";
import IconTwitter from "components/icons/IconTwitter.vue";
import IconLinkedin from "components/icons/IconLinkedin.vue";
import IconYen from "components/icons/IconYen.vue";

Vue.use(Vuetify);

// How to use the color palette across the app (as DOM classes or css variables)
// https://vuetifyjs.com/en/customization/theme/#customizing

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#161616",
        secondary: "#3454D1",
        accent: "#E6F930",
        anchor: "#3454D1",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        gray: "#E0E0E0",
        gray_lighter: "#f5f5f5",
        gray_darker: "#808080"
      }
    }
  },
  lang: {
    locales: { ja, en }
  },
  iconfont: "md",
  icons: {
    rocket: {
      component: IconRocket,
      props: {
        name: "rocket"
      }
    },
    global: {
      component: IconGlobal,
      props: {
        name: "global"
      }
    },
    twitter: {
      component: IconTwitter,
      props: {
        name: "twitter"
      }
    },
    linkedin: {
      component: IconLinkedin,
      props: {
        name: "linkedin"
      }
    },
    yen: {
      component: IconYen,
      props: {
        name: "yen"
      }
    }
  }
});
