import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Meta from "vue-meta";
import i18n from "./i18n";
import { Trans } from "./plugins/translation";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import "assets/scss/main.scss";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:
      "https://251697767f2d47538a4704be402c0fed@o422784.ingest.sentry.io/5380791",
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true
      })
    ]
  });
}

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.config.productionTip = false;

Vue.use(Meta);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
