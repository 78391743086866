<template>
  <v-card
    class="mx-auto d-flex flex-column flex-sm-row align-center py-4 px-3"
    outlined
    rounded
  >
    <v-card-title>
      <v-img max-width="190" src="/wc_logo.svg"></v-img>
    </v-card-title>
    <v-card-text
      class="font-weight-bold text--primary text-center text-sm-left pa-sm-0"
    >
      {{ $t("title_first") }}<br />{{ $t("title_second") }}
    </v-card-text>
    <v-card-actions>
      <v-btn
        :href="workcircle"
        color="primary"
        rounded
        class="font-weight-bold px-4 py-2"
        >{{ $t("check_out") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<i18n>
{
  "ja": {
    "check_out": "詳細をみる",
      "title_first": "従業員がキャリアについて",
      "title_second": "自由に本音で語れる匿名コミュニティ"
  },
  "en": {
    "check_out": "check out",
      "title_first": "A community where employees can",
      "title_second": "freely talk about their careers anonymously"
  }
}
</i18n>

<script>
export default {
  data: () => {
    return {
      workcircle:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000?ref=opensalary"
          : "https://workcircle.app?ref=opensalary"
    };
  }
};
</script>
