<template>
  <v-app>
    <!-- Banner for a new feature -->
    <template v-if="showWorkCircleBanners">
      <v-system-bar
        v-if="showBanner && locale.code == 'ja'"
        class="white--text"
        color="#FE8A41"
        window
        :height="$vuetify.breakpoint.xsOnly ? 74 : 55"
      >
        <v-spacer></v-spacer>

        <div class="d-flex align-center primary--text justify-space-between">
          <v-chip
            class="ma-1 ma-sm-2 flex-shrink-0 flex-grow-1"
            small
            color="white"
            href="https://www.workcircle.app/?ref=opensalary"
          >
            <v-img width="100" src="/wc_logo.svg"></v-img>
          </v-chip>
          <span class="white--text font-weight-bold">{{
            $t("workcircle_copy")
          }}</span>
        </div>

        <v-spacer></v-spacer>
        <v-btn icon @click="hideBanner">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
    </template>

    <!-- Navbar -->
    <v-app-bar max-height="65" flat color="primary">
      <!-- Logo, Language switcher and search bar (mobile) -->
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-expand-x-transition>
          <transition-group name="list" tag="div" class="d-flex align-center">
            <!-- Logo (mobile) -->
            <logo key="logo" v-show="searchClosed" />
            <!-- Language switcher (mobile) -->
            <div
              key="language-switcher"
              class="mx-sm-6 mx-2"
              id="language-switcher"
              v-show="searchClosed"
            >
              <a :href="oppositeLocalePath">
                <v-icon small color="grey">mdi-web</v-icon>
                <button type="button" class="language-button" color="white">
                  {{ $t("other_language") }}
                </button>
              </a>
            </div>
          </transition-group>
        </v-expand-x-transition>
        <v-spacer v-if="searchClosed"></v-spacer>
        <!-- Seach bar (mobile) -->
        <v-autocomplete
          v-model="search"
          @focus="searchFocused = true"
          @blur="searchFocused = false"
          :filter="companySearch"
          :menu-props="{ closeOnContentClick: true }"
          :items="companyList"
          :class="{ 'closed mr-6': searchClosed }"
          :placeholder="$t('search_companies')"
          :background-color="searchClosed ? 'primary' : 'white'"
          append-icon=""
          :no-data-text="$t('no_companies_found')"
          item-text="name"
          item-value="name"
          class="expanding-search"
          cache-items
          rounded
          single-line
          hide-details
        >
          <template v-slot:append>
            <v-icon :color="searchClosed ? 'white' : 'primary'"
              >mdi-magnify</v-icon
            >
          </template>
          <template v-slot:item="{ item }">
            <v-list-item
              :to="
                $i18nRoute({
                  name: 'showCompanyOld',
                  params: {
                    company_slug: item.slug
                  }
                })
              "
            >
              {{ item.name }}
            </v-list-item>
          </template>
        </v-autocomplete>
      </template>
      <!-- Logo, Language switcher and search bar (pc) -->
      <template v-else>
        <!-- Logo (pc) -->
        <logo />
        <!-- Language Switcher (pc) -->
        <div class="mx-sm-6 mx-2" id="language-switcher">
          <a :href="oppositeLocalePath">
            <v-icon small color="grey">mdi-web</v-icon>
            <button type="button" class="language-button" color="white">
              {{ $t("other_language") }}
            </button>
          </a>
        </div>
        <!-- Search bar (pc) -->
        <v-autocomplete
          v-model="search"
          :filter="companySearch"
          :items="companyList"
          :placeholder="$t('search_companies')"
          :menu-props="{ closeOnContentClick: true }"
          item-text="name"
          item-value="name"
          class="flex-grow-1 mr-2"
          background-color="white"
          append-icon=""
          :no-data-text="$t('no_companies_found')"
          cache-items
          rounded
          single-line
          hide-details
        >
          <template v-slot:append>
            <v-icon color="primary">mdi-magnify</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item
              :to="
                $i18nRoute({
                  name: 'showCompanyOld',
                  params: {
                    company_slug: item.slug
                  }
                })
              "
            >
              {{ item.name }}
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-spacer />
      </template>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in menuList"
          text
          color="white"
          :key="item.title"
          :to="
            $i18nRoute({
              name: item.name,
              params: { role: null }
            })
          "
          :class="{ 'v-btn--active ': isActive(item.name) }"
          exact
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <!-- Button for submitting compensation -->
      <v-btn
        active-class="false"
        exact
        class="hidden-sm-and-down"
        rounded
        color="secondary"
        :to="$i18nRoute({ name: 'submitCompensationSelection' })"
      >
        {{ $t("add_compensation") }}
      </v-btn>
      <!-- Hamburger menu for mobile -->
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" class="hidden-md-and-up">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in menuList"
            :key="i"
            :to="$i18nRoute({ name: item.name })"
            exact
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item href="mailto:hello@opensalary.jp">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
          <v-list-item
            :to="
              $i18nRoute({
                name: 'submitCompensationSelection'
              })
            "
          >
            <v-list-item-title>{{ $t("add_compensation") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Modal to encourage salary entry -->
    <div class="text-center">
      <v-dialog
        v-model="showModal"
        width="500"
        @click:outside="closeModalForCurrentSession"
      >
        <v-card class="pa-8 pa-sm-12" id="cta-modal">
          <v-icon id="close-modal" @click="closeModalForCurrentSession"
            >mdi-close</v-icon
          >
          <v-row justify="center" class="text-center pa-6">
            <p class="font-weight-bold h-6">
              {{ $t("add_your_compensation") }}
            </p>
          </v-row>
          <v-card-text class="grey--text text--darken-4">
            {{ $t("call_to_action_dialog") }}
          </v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="12">
                <v-card-actions
                  class="d-flex flex-sm-row flex-column justify-center"
                >
                  <v-btn
                    outlined
                    rounded
                    color="secondary"
                    @click="closeModalPermanently"
                    :block="$vuetify.breakpoint.xsOnly"
                    class="mb-4 mb-sm-0"
                  >
                    {{ $t("already_submitted") }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    rounded
                    :block="$vuetify.breakpoint.xsOnly"
                    @click="
                      closeModalForCurrentSession();
                      jumpToSubmitCompensation();
                    "
                  >
                    <v-icon left dark large>mdi-plus</v-icon>
                    {{ $t("submit_compensation") }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions
            class="d-flex justify-center"
            @click="closeModalForCurrentSession"
          >
            <v-btn text plain class="text-decoration-underline">
              {{ $t("submit_later") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-main><router-view /></v-main>

    <!-- Footer -->
    <v-footer color="primary" class="mt-4">
      <v-container>
        <template>
          <v-row v-if="locale.code == 'ja'">
            <v-col cols="12">
              <work-circle-banner />
            </v-col>
          </v-row>
          <v-divider class="my-6" dark v-if="locale.code == 'ja'"></v-divider>
        </template>
        <v-row class="flex-column-reverse flex-md-row">
          <!-- Copyright and socials -->
          <v-col class="primary white--text" cols="12" md="3">
            <v-row class="align-center text-center text-md-start">
              <!-- Opensalary.jp -->
              <v-col
                md="12"
                cols="12"
                order-md="1"
                order="2"
                class="pb-md-0 py-0 py-md-3"
              >
                <span class="heading-5 font-weight-bold">OpenSalary.jp</span>
              </v-col>
              <!-- Copyright -->
              <v-col
                md="3"
                cols="12"
                order-md="2"
                order="3"
                class="white-space-nowrap pt-md-0 pr-md-0 pt-1"
              >
                <v-icon dark x-small>mdi-copyright</v-icon
                ><span class="caption">{{ new Date().getFullYear() }}</span>
              </v-col>
              <!-- Socials -->
              <v-col
                md="9"
                cols="12"
                order-md="3"
                order="1"
                class="pt-md-0 pl-md-0 pb-1 pb-md-3 white-space-nowrap"
              >
                <!-- twitter -->
                <v-btn
                  icon
                  href="https://twitter.com/opensalary_jp"
                  color="#1D9BF0"
                  target="_blank"
                >
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <!-- facebook -->
                <v-btn
                  icon
                  href="https://www.facebook.com/OpenSalary.jp"
                  color="#3877EC"
                  target="_blank"
                >
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <!-- instagram -->
                <v-btn
                  icon
                  href="https://www.instagram.com/opensalary_jp"
                  color="#FF0075"
                  target="_blank"
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <!-- linkedin -->
                <v-btn
                  icon
                  href="https://www.linkedin.com/company/opensalary"
                  color="#2D64BC"
                  target="_blank"
                >
                  <v-icon>mdi-linkedin</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- Core salary info links -->
          <v-col cols="12" md="9">
            <v-row>
              <v-col class="primary white--text" cols="6" md="4">
                <span class="heading-6 font-weight-bold">{{
                  $t("salary_data")
                }}</span>
                <ul class="footer-list">
                  <li v-for="item in footerNavListOne" v-bind:key="item.title">
                    <v-hover v-slot:default="{ hover }">
                      <router-link
                        :class="
                          hover ? 'body-2 text-decoration-underline' : 'body-2'
                        "
                        :to="item.link"
                      >
                        {{ item.title }}
                      </router-link>
                    </v-hover>
                  </li>
                </ul>
              </v-col>
              <!-- CTAs to submit salary entries and ladder -->
              <v-col class="primary white--text" cols="6" md="4">
                <span class="heading-6 primary-lighter--text font-weight-bold"
                  >Contribute</span
                >
                <ul class="footer-list">
                  <li v-for="item in footerNavListTwo" v-bind:key="item.title">
                    <v-hover v-slot:default="{ hover }">
                      <router-link
                        :class="
                          hover ? 'body-2 text-decoration-underline' : 'body-2'
                        "
                        :to="item.link"
                      >
                        {{ item.title }}
                      </router-link>
                    </v-hover>
                  </li>
                  <li>
                    <v-hover v-slot:default="{ hover }">
                      <a
                        :class="
                          hover ? 'body-2 text-decoration-underline' : 'body-2'
                        "
                        :href="formUrl"
                      >
                        {{ $t("submit_ladder") }}
                      </a>
                    </v-hover>
                  </li>
                </ul>
              </v-col>
              <!-- Company info -->
              <v-col class="primary white--text" cols="6" md="4">
                <span class="heading-6 primary-lighter--text font-weight-bold"
                  >OPENSALARY</span
                >
                <ul class="footer-list">
                  <li
                    v-for="item in footerNavListThree"
                    v-bind:key="item.title"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <router-link
                        :class="
                          hover ? 'body-2 text-decoration-underline' : 'body-2'
                        "
                        :to="item.link"
                      >
                        {{ item.title }}
                      </router-link>
                    </v-hover>
                  </li>
                  <li>
                    <v-hover v-slot:default="{ hover }">
                      <a
                        :class="
                          hover ? 'body-2 text-decoration-underline' : 'body-2'
                        "
                        href="mailto:hello@opensalary.jp"
                        >Contact Us</a
                      >
                    </v-hover>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<i18n>
{
  "en": {
    "head_title": "Software Engineering Salaries in Japan",
    "home": "Home",
    "see_salaries": "Salaries",
    "company_listing": "Companies",
    "about": "About",
    "blog": "Blog",
    "privacy_policy": "Privacy Policy",
    "add_compensation": "Submit Compensation",
    "other_language": "日本語",
    "headline": "Software Engineer Salaries in Japan",
    "meta_description": "Explore detailed information on over 1100+ salary entries for software engineers in Japan from over 300 companies such as Google, LINE, Indeed, Rakuten and Mercari.",
    "submit_ladder": "Submit Career Ladder",
    "see_by_yoe": "Years of Experience",
    "salary_data": "Salary Data",
    "add_your_compensation": "Add Your Compensation!",
    "call_to_action_dialog": "We rely on data contributed from users like you. Help others like you and add your compensation. 100% anonymous. No email required. Only takes 45 seconds.",
    "submit_compensation": "Submit",
    "already_submitted": "Already Submitted",
    "submit_later": "Submit Later",
    "banner_badge_content": "New",
    "new_feature_submit_compensation": "Directly upload an offer letter! {0}",
    "workcircle_released": "{0}, an anonymous SNS for employees.",
    "workcircle_copy": "Anonymous community for employees of tech companies in Japan",
    "released": "We've launched WorkCircle",
    "released_sm": "We've launched WorkCircle, an anonymous SNS for employees.",
    "here": "Submit here.",
    "search_companies": "Company name",
    "no_companies_found": "No companies found"
  },
  "ja": {
    "head_title": "ソフトウェアエンジニアの年収",
    "home": "ホーム",
    "see_salaries": "みんなの年収",
    "company_listing": "企業一覧",
    "about": "About",
    "blog": "ブログ",
    "privacy_policy": "プライバシーポリシー ",
    "add_compensation": "年収を追加",
    "other_language": "English",
    "headline": "Software Engineer Salaries in Japan",
    "meta_description": "Google、LINE、Indeed、楽天、メルカリなど300社を超えるデータから1100件以上のソフトウェアエンジニアの給与情報を検索。",
    "submit_ladder": "等級制度を提出",
    "salary_data": "年収データ",
    "see_by_yoe": "経験年数",
    "add_your_compensation": "年収を追加しましょう！",
    "call_to_action_dialog": "OpenSalaryはすべてのユーザーに情報を無償で公開しています。アカウント作成やメールアドレスの登録は必要ありません。これはコミュニティの協力のもと成り立っています。あなたもぜひ、給与情報の提供にご協力ください。",
    "submit_compensation": "年収を提出",
    "already_submitted": "提出済み",
    "submit_later": "後で提出する",
    "banner_badge_content": "NEW",
    "new_feature_submit_compensation": " オファーレターや給与明細を直接アップロードして、{0}できるようになりました！",
    "workcircle_released": "従業員がキャリアについて自由に本音で語れる匿名コミュニティ、{0}",
    "workcircle_copy": "外資系・テック企業社員専用の匿名コミュニティー",
    "released": "WorkCircleをリリースしました!",
    "released_sm": "匿名コミュニティWorkCircleをリリースしました!",
    "here": "給与提出",
    "search_companies": "企業名",
    "no_companies_found": "企業が見つかりませんでした"
  }
}
</i18n>

<script>
import store from "store";
import Logo from "./components/Logo.vue";
import WorkCircleBanner from "./components/WorkCircleBanner.vue";
import { SUPPORTED_LOCALES } from "constants/locale";
import api from "services/api";
import { showWorkCircleBanners } from "./constants/featureFlags";

export default {
  name: "App",
  components: { Logo, WorkCircleBanner },
  data: function() {
    return {
      search: "",
      path: "/",
      locales: SUPPORTED_LOCALES,
      // Change this to true once there is banner we want to show
      showBanner: true,
      showWorkCircleBanners,
      showModal: false,
      searchFocused: false,
      companyList: [],
      workcircle:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000?ref=opensalary"
          : "https://workcircle.app?ref=opensalary"
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("head_title"),
      // all titles will be injected into this template
      titleTemplate: "%s | OpenSalary",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta_description")
        },
        {
          vmid: "og-title",
          property: "og:title",
          content: this.$t("head_title") + "| OpenSalary"
        },
        {
          vmid: "og-description",
          property: "og:description",
          content: this.$t("meta_description")
        }
      ]
    };
  },
  created() {
    this.getCompanies();
    this.startCallToActionTimer();
  },
  mounted() {
    // Changes localization to match i18n setting for vuetify
    this.$vuetify.lang.current = this.$i18n.locale;
    if (localStorage.hideBanner == "worcircle") {
      this.showBanner = false;
    }
  },
  methods: {
    hideBanner() {
      this.showBanner = false;
      localStorage.hideBanner = "workcircle";
    },
    displayCallToActionModal() {
      this.showModal = true;
    },
    closeModalForCurrentSession() {
      localStorage.setItem("modalLastDismissed", new Date().getTime());
      this.showModal = false;
    },
    closeModalPermanently() {
      localStorage.setItem("userLastSubmitted", new Date().getTime());
      this.showModal = false;
    },
    startCallToActionTimer() {
      const threeDaysSinceDismissed =
        localStorage.getItem("modalLastDismissed") &&
        new Date().getTime() -
          Number.parseInt(localStorage.getItem("modalLastDismissed"), 10) >
          3 * 24 * 60 * 60 * 1000;
      const threeMonthsSinceSubmitted =
        localStorage.getItem("userLastSubmitted") &&
        new Date().getTime() -
          Number.parseInt(localStorage.getItem("userLastSubmitted"), 10) >
          3 * 30 * 24 * 60 * 60 * 1000;

      if (threeMonthsSinceSubmitted !== null && !threeMonthsSinceSubmitted)
        return;
      if (threeDaysSinceDismissed !== null && !threeDaysSinceDismissed) return;

      setTimeout(() => {
        this.displayCallToActionModal();
      }, 30000);
    },
    jumpToSubmitCompensation() {
      const URL = this.$router.resolve(
        this.$i18nRoute({ name: "submitCompensationSelect" })
      ).href;
      window.open(URL, "_blank");
    },
    isHome(name) {
      const regex = /^\/roles\/[a-z-]+$/g;
      return name === "home" && regex.test(this.$route.path);
    },
    isExploreSalaries(name) {
      const regex = /^\/explore-salaries$/g;
      return name === "exploreSalaries" && regex.test(this.$route.path);
    },
    isCompanyListing(name) {
      const regex = /^\/companies$/g;
      return name === "companyListing" && regex.test(this.$route.path);
    },
    isActive(name) {
      return (
        this.isExploreSalaries(name) ||
        this.isCompanyListing(name) ||
        this.isHome(name)
      );
    },
    async getCompanies() {
      const { data } = await api.getCompanies();
      this.companyList = data.items;
    },
    companySearch(company, typedText) {
      // TODO: improve the search for all Japanese characters
      // https://github.com/drewpterry/project-alpha/issues/732

      const hasValue = val => (val != null ? val : "");

      const nameEn = hasValue(company.name_en)
        .toString()
        .toLowerCase();
      const nameJa = hasValue(company.name_ja).toString();
      let searchTerm = hasValue(typedText);

      // if search term are English letters
      if (/^[a-zA-Z]/.test(searchTerm)) {
        searchTerm = searchTerm.toLowerCase();
      } else {
        searchTerm = searchTerm.toString();
      }

      const matchNameEn = nameEn.indexOf(searchTerm) > -1;
      const matchNameJa = nameJa.indexOf(searchTerm) > -1;

      return matchNameEn || matchNameJa;
    }
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    formUrl() {
      if (this.locale.code === "en") {
        return "https://forms.gle/i4cb81XYiojTYtb47";
      }
      return "https://forms.gle/ZQPZTrSuGwSrc6bq8";
    },
    oppositeLocale() {
      return SUPPORTED_LOCALES.find(loc => loc.code != this.locale.code);
    },
    oppositeLocalePath() {
      return this.$router.resolve({
        name: this.$route.name,
        params: { ...this.$route.params, locale: this.oppositeLocale.param },
        query: this.$route.query
      }).href;
    },
    footerNavListOne() {
      return [
        {
          title: this.$t("see_salaries"),
          link: this.$i18nRoute({ name: "exploreSalaries" })
        },
        {
          title: this.$t("company_listing"),
          link: this.$i18nRoute({ name: "companyListing" })
        },
        {
          title: this.$t("see_by_yoe"),
          link: this.$i18nRoute({ name: "yearsOfExperience" })
        }
      ];
    },
    footerNavListTwo() {
      return [
        {
          title: this.$t("add_compensation"),
          link: this.$i18nRoute({ name: "submitCompensationSelection" })
        }
      ];
    },
    footerNavListThree() {
      return [
        {
          title: this.$t("home"),
          link: this.$i18nRoute({ name: "home" })
        },
        {
          title: this.$t("about"),
          link: this.$i18nRoute({ name: "about" })
        },
        {
          title: this.$t("blog"),
          link: this.$i18nRoute({ name: "blog" })
        },
        {
          title: this.$t("privacy_policy"),
          link: this.$i18nRoute({ name: "privacyPolicy" })
        }
      ];
    },
    menuList() {
      return [
        {
          title: this.$t("home"),
          name: "home"
        },
        {
          title: this.$t("see_salaries"),
          name: "exploreSalaries"
        },
        {
          title: this.$t("company_listing"),
          name: "companyListing"
        },
        {
          title: this.$t("about"),
          name: "about"
        },
        {
          title: this.$t("blog"),
          name: "blog"
        }
      ];
    },
    searchClosed() {
      return !this.searchFocused && this.search === "";
    }
  },
  watch: {
    $route(to) {
      this.path = this.locale.base
        ? to.path.substring(this.locale.base.length)
        : to.path;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  a {
    text-decoration: none;
    color: white;
  }
}

.footer-list {
  list-style-type: none;
  padding: 0;
  li {
    margin-top: 5px;
    a {
      color: var(--v-gray-base);
      text-decoration: none;
    }
  }
}

.banner-link {
  text-decoration: underline;
  font-weight: 600;
  color: inherit;
}

#nav {
  padding: 30px;
  position: relative;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.language-button {
  cursor: pointer;
  position: relative;
  top: 2px;
  height: 1.6em;
  line-height: 1.6em;
  margin-left: 8px;
  color: white;
  transition: all 0.3s 0s ease;
}

.language-button::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: white;
  opacity: 0;
}

.language-button:hover {
  transform: translateY(-0.2em);
}

.language-button:hover::after {
  opacity: 1;
}

#language-switcher {
  white-space: nowrap;
}

#cta-modal > #close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.expanding-search {
  ::v-deep {
    .v-icon.mdi-magnify {
      color: white;
    }
  }
}
.closed {
  max-width: 25px;
}
.white-space-nowrap {
  white-space: nowrap;
}
</style>
