import { render, staticRenderFns } from "./IconRocket.vue?vue&type=template&id=b0cf2646&scoped=true&"
var script = {}
import style0 from "./IconRocket.vue?vue&type=style&index=0&id=b0cf2646&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0cf2646",
  null
  
)

export default component.exports