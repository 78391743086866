export const SUPPORTED_LOCALES = [
  {
    param: "en",
    code: "en",
    base: "/en",
    flag: "us",
    name: "English"
  },
  {
    param: null,
    code: "ja",
    base: "",
    flag: "ja",
    name: "日本語"
  }
];
