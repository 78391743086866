import axios from "axios";
import store from "store";

const API = process.env.VUE_APP_API_BASE_URL || "/api";

//const axios_request = axios.create({
//baseURL: "www.example.com"
//});

function get(path, params = {}) {
  const locale = store.getters.locale.code;
  const params_with_locale = Object.assign(params, { locale: locale });
  return axios.get(`${API}/${path}`, { params: params_with_locale });
}

function post(path, data, headers = {}) {
  const locale = store.getters.locale.code;

  return axios.post(
    `${API}/${path}`,
    data,
    { params: { locale: locale } },
    headers
  );
}

function ping() {
  return get("ping");
}

function getCompanies(query) {
  return get("companies", query);
}

function getFeaturedCompanies(query) {
  return get("featured-companies", query);
}

function getSalaries(query) {
  return get("salary-entries", query);
}

function getSalary(id) {
  return get(`salary-entries/${id}`);
}

function createSalary(salaryEntry) {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };
  return post("salary-entries", salaryEntry, headers);
}

function createVerifiedSalaryDocumentsEntry(entry) {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };
  return post("verified_salary_document_entries", entry, headers);
}

function getCompany({ slug, query }) {
  return get(`companies/${slug}`, query);
}

function getJobRole(slug) {
  return get(`job_roles/${slug}`);
}

function getTags() {
  return get("tags");
}

function getJobs(query) {
  return get("jobs", query);
}

function createLead(lead) {
  return post("leads", lead);
}

function getArticles() {
  return get("articles");
}

function getArticle(slug) {
  return get(`articles/${slug}`);
}

function getCities() {
  return get("cities");
}

function getEducationLevels() {
  return get("education_levels");
}

function getJobRoles() {
  return get("job_roles");
}

function getLadders(companySlug) {
  return get(`companies/${companySlug}/ladders`);
}

export default {
  ping,
  getCompanies,
  getCompany,
  getJobs,
  createLead,
  getArticles,
  getArticle,
  getFeaturedCompanies,
  getSalaries,
  getSalary,
  createSalary,
  createVerifiedSalaryDocumentsEntry,
  getCities,
  getJobRoles,
  getJobRole,
  getTags,
  getEducationLevels,
  getLadders
};
