import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import i18n from "./i18n";
import { SUPPORTED_LOCALES } from "./constants/locale";

Vue.use(Router);

// Returns locale configuration
function getLocale(locale = "ja") {
  return SUPPORTED_LOCALES.find(loc => loc.code === locale);
}

function load(component) {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: `/:locale(en)?`,
      component: {
        //template: '<router-view></router-view>'
        render(c) {
          return c("router-view");
        }
      },
      beforeEnter(to, from, next) {
        const locale = getLocale(to.params.locale);
        store.dispatch("setLocale", locale);
        i18n.locale = locale.code;
        next();
      },
      children: [
        {
          path: "companies/:company_slug/roles/:role_slug",
          name: "showCompany",
          component: load("CompanyShowView"),
          props: route => ({
            role_slug: route.params.role_slug,
            company_slug: route.params.company_slug
          })
        },
        {
          path: "companies/:company_slug",
          name: "showCompanyOld",
          component: load("CompanyShowView"),
          props: route => ({
            company_slug: route.params.company_slug,
            role_slug: "software-engineer"
          })
        },
        {
          path: "submit-compensation",
          name: "submitCompensation",
          component: load("SubmitCompensationView")
        },
        {
          path: "submit-compensation/thank-you",
          name: "thankYouForSubmitting",
          component: load("ThankYouForSubmittingView")
        },
        {
          path: "submit-compensation-selection",
          name: "submitCompensationSelection",
          component: load("SubmitCompensationSelectionView")
        },
        {
          path: "submit-compensation-selection/verified",
          name: "NewSubmitVerifiedCompensation",
          component: load("NewSubmitVerifiedCompensationView")
        },
        {
          path: "verified-salaries",
          name: "verifiedSalariesLandingPage",
          component: load("VerifiedLandingPageView")
        },
        {
          path: "single-salary/:id",
          name: "salaryShow",
          component: load("SalaryShowView")
        },
        {
          path: "blog",
          name: "blog",
          component: load("ArticleListingView")
        },
        {
          path: "blog/:slug",
          name: "showArticle",
          component: load("ArticleShowView")
        },
        {
          path: "companies",
          name: "companyListing",
          component: load("CompanyListingView"),
          props: route => ({ role: route.query.role })
        },
        {
          path: "years-of-experience",
          name: "yearsOfExperience",
          component: load("YearsOfExperienceOverallView")
        },
        {
          path: "explore-salaries",
          name: "exploreSalaries",
          component: load("ExploreSalariesView"),
          props: route => ({ role: route.query.role })
        },
        {
          path: "about",
          name: "about",
          component: load("AboutView")
        },
        {
          path: "privacy-policy",
          name: "privacyPolicy",
          component: load("PrivacyPolicyView")
        },
        {
          path: "roles/:slug",
          name: "roleShow",
          component: load("RoleShowView"),
          props: route => ({ role_slug: route.params.slug })
        },
        {
          path: "/",
          name: "home",
          redirect: { path: "roles/software-engineer" }
        },
        {
          path: "*",
          redirect: {
            name: "home"
          },
          component: load("SalaryListingView")
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
